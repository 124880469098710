import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from "@elastic/eui";
import TableFeatures from "components/Outdoor_Cameras/IN-9408_WQHD/Product_Features/TableFeatures";
import TableStatus from 'components/Outdoor_Cameras/IN-9408_WQHD/Product_Features/TableStatus';
import NavButtons from "components/Outdoor_Cameras/IN-9408_WQHD/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "IN-9408 WQHD Manual Product Features",
  "path": "/Outdoor_Cameras/IN-9408_WQHD/Product_Features/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-9408 WQHD is the first INSTAR Outdoor IP Surveillance camera, utilizing our new Sony 1440p / WQHD STARVIS sensor.ilizing our new Sony 1440p / WQHD STARVIS sensor.",
  "image": "./P_SearchThumb_IN-9008HD_Features.png",
  "social": "/images/Search/P_SearchThumb_IN-9408WQHD_Features.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9008HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title="IN-9408 WQHD Manual Product Features" dateChanged="2018-01-22" author="Mike Polinowski" tag="INSTAR IP Camera" description="IN-9408 WQHD Quick Installation" image="/images/Search/P_SearchThumb_IN-9408HD_Quick_Installation.png" twitter="/images/Search/P_SearchThumb_IN-9408HD_Quick_Installation.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Aussenkameras/IN-9408_WQHD/Produkt_Features/" locationFR="/fr/Outdoor_Cameras/IN-9408_WQHD/Product_Features/" crumbLabel="Features" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-9408-wqhd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-9408-wqhd-manual",
        "aria-label": "in 9408 wqhd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9408 WQHD Manual`}</h2>
    <h3 {...{
      "id": "product-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#product-features",
        "aria-label": "product features permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Features`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d1c10ee6afa974391599f6b577b25e92/03914/IN-9408HD_Product-Overview.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+ElEQVQ4y21Ty46jMBDk/78n19xyirjNARSCIoJiEmDBjdtvG+8uHbHZ0dQJ7C53u6qcpZRijCGE9BPWdf389d5ba40x1tqUUmatlVIKIZZloT3vPZXGGAHAWhtj3Mla618b1nXNENE5h4iccwAQQmitU0rOOa2134CINJpzTim1bPhHhg3zPAOAlDKltCyLtXZd1xijEMIYs59Ile+xtdZSSs65tVYpRfehD845taXLO+eWZek3/O2cUkLEaZqoyDlHdQAQQqA+u3JEJmne5BCClNJ7T7JTHckWY/Te75qHEGh+QvbpitbaGEPaOueklABwv9+7rosxfhrxnRxC6LpuHEeqQMR5nqdpyvP8er2S5mTED+SUEqm6dw4hIOIwDJzzfeU/snOO5uGcV1VF7nvvAcAYQ84Rh3SiLJAK2X6NoiiOx+P5fDbGtG2b53lVVW3bUmwYY13XTdPEORcb3j7TnGVZfn19nU6npmk454fDoSgKxljTNGVZ1nXNGENEOgsAvPcZ6dR1HWOs7/umacZxfDwel8vldrs9n0/OOWNsnue2badpEkIopYZhiDFmFKZxHCkeUkpjDAXYe2+MoXi/Xq+6rsdxFEIAACK+1XYbKBLDMMzzTJ5LKRFRSkm7exYov2/y/p6J3Pc9jaOUwg2Uqm9v+w/5NwQz02B/IeWyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d1c10ee6afa974391599f6b577b25e92/e4706/IN-9408HD_Product-Overview.avif 230w", "/en/static/d1c10ee6afa974391599f6b577b25e92/d1af7/IN-9408HD_Product-Overview.avif 460w", "/en/static/d1c10ee6afa974391599f6b577b25e92/7f308/IN-9408HD_Product-Overview.avif 920w", "/en/static/d1c10ee6afa974391599f6b577b25e92/da1db/IN-9408HD_Product-Overview.avif 1173w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d1c10ee6afa974391599f6b577b25e92/a0b58/IN-9408HD_Product-Overview.webp 230w", "/en/static/d1c10ee6afa974391599f6b577b25e92/bc10c/IN-9408HD_Product-Overview.webp 460w", "/en/static/d1c10ee6afa974391599f6b577b25e92/966d8/IN-9408HD_Product-Overview.webp 920w", "/en/static/d1c10ee6afa974391599f6b577b25e92/edf1e/IN-9408HD_Product-Overview.webp 1173w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d1c10ee6afa974391599f6b577b25e92/81c8e/IN-9408HD_Product-Overview.png 230w", "/en/static/d1c10ee6afa974391599f6b577b25e92/08a84/IN-9408HD_Product-Overview.png 460w", "/en/static/d1c10ee6afa974391599f6b577b25e92/c0255/IN-9408HD_Product-Overview.png 920w", "/en/static/d1c10ee6afa974391599f6b577b25e92/03914/IN-9408HD_Product-Overview.png 1173w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d1c10ee6afa974391599f6b577b25e92/c0255/IN-9408HD_Product-Overview.png",
              "alt": "IN-9408 WQHD Produkt Features",
              "title": "IN-9408 WQHD Produkt Features",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TableFeatures mdxType="TableFeatures" />
    <h3 {...{
      "id": "status-leds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#status-leds",
        "aria-label": "status leds permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Status LEDs`}</h3>
    <TableStatus mdxType="TableStatus" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      